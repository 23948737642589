@import 'styles/variables.scss';
.error-modal {
  position: relative;
  z-index: 10000;
}
.errors {
  .error-text {
    position: relative;
    font-size: 1.4rem;
    font-weight: bold;
    color: $col-error;
    padding: 8px 16px;
    .error-icon {
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 12px;
      left: 0;
      background: $col-error;
      &::before,
      &::after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 1px;
        border-radius: 2px;
        background: $col-white;
      }
      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
