@import 'styles/variables.scss';

.screen {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  z-index: 1000;
}
.screen-inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  &.has-footer {
    > .contents {
      padding-bottom: 220px;
    }
  }
  &.is-bg-black {
    background: #000;
  }
  &.is-hidden {
    > .screen-header,
    > .screen-footer {
      display: none;
    }
    > .contents {
      margin: 0;
      padding: 0;
    }
  }
}
.screen-header {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  padding: 0 12px;
  border-bottom: 1px $col-gray4 solid;
  border-radius: 10px 10px 0 0;
}
.header-content {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.close-button-wrap {
  backface-visibility: hidden;
  z-index: 100;
  filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.6));
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}
.close-button-text {
  display: inline-block;
  font-weight: bold;
}
.right-top-elem {
  font-weight: bold;
  color: $col-secondary;
}
.contents {
  width: 100%;
  height: 100vh;
  padding-bottom: 155px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .content {
    margin-bottom: 20px;
  }
}
.title {
  position: relative;
  overflow: hidden;
  margin: 0 !important;
  padding: 12px;
  background: $col-white;
  border-bottom: 1px solid $item-border-color;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.5px;
  &::before {
    content: '';
    position: absolute;
    background: $col-primary;
    width: 4px;
    height: 40px;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
  }
}
.screen-footer {
  z-index: 1;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 12px;
  width: 100%;
  border-top: 1px $item-border-color solid;
  background: #fff;
}

.screen-ad-footer {
  bottom: 50px;
}

.select-max-txt {
  color: $col-error;
  font-weight: bold;
}
