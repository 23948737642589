@import 'styles/variables.scss';

.suggest-input {
  position: relative;
  z-index: 1;
  .suggest-form {
    border: 1px $col-gray3 solid;
    position: relative;
    background: #fff;
    width: 100%;
    height: 34px;
    border-radius: $border-radius;
    padding: 0 8px;
    font-size: $font-size;
    &:placeholder-shown {
      color: $col-gray4;
    }
    &::-webkit-input-placeholder {
      color: $col-gray4;
    }
  }
  .suggest {
    position: absolute;
    background: $col-gray1;
    width: 300px;
    top: 46px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    max-width: 280px;
    margin: -12px 0 0 12px;
    border-radius: 4px;
    .input-text {
      height: 48px;
      padding: 12px;
      border-bottom: 1px $item-border-color solid;
      font-weight: bold;
    }
    .list {
      margin: 0;
      .item {
        cursor: pointer;
        padding: 12px 0 12px 1rem;
        border-bottom: 1px $item-border-color solid;
        display: flex;
        align-items: center;
        .md-icon {
          width: 12px;
          min-width: 12px;
          height: 12px;
          font-size: 1.6rem !important;
          font-weight: normal;
        }
      }
    }
  }
}
