@import "styles/variables.scss";
.close-button {
  display: inline-block;
  width: 48px;
  height: 48px;
  position: relative;
  cursor: pointer;
  &.is-sm {
    width: 20px;
    height: 20px;
  }
}
.close-button span::before,
.close-button span::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 2px;
  border-radius: 2px;
  background: $col-base;
}
.close-button {
  &.is-sm span::before,
  &.is-sm span::after {
    width: 12px;
  }
}
.close-button span::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.close-button span::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
