@import 'styles/variables.scss';
.item {
  padding: 12px;
  border-bottom: 1px $item-border-color solid;
  .link {
    color: $col-base;
    display: block;
    width: 100%;
    height: auto;
  }
}
