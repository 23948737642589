@import 'styles/variables.scss';

.search-button-holder {
  display: flex;
  position: relative;
  .text {
    position: relative;
    width: 320px;
  }
  .search-button {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .md-icon {
      font-size: 2rem !important;
      font-weight: normal;
      color: #fff;
    }
  }
}
