@import 'styles/variables.scss';

.suggest-input {
  position: relative;
  z-index: 1;
  font-size: 1.2rem;
}

.input-form-text {
  border: 1px $col-gray3 solid;
  position: relative;
  background: #fff;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  padding: 0 12px;
  font-size: $form-font-size;
  &:placeholder-shown {
    color: $col-gray4;
  }
  &::-webkit-input-placeholder {
    color: $col-gray4;
  }
  &:active {
    opacity: 0.7;
  }
}

.suggest {
  background: $col-gray1;
  border-radius: 4px;
  font-weight: bold;
  z-index: 10;
  overflow: hidden;
  position: relative;
  .input-text {
    height: 48px;
    padding: 12px 0;
    border-bottom: 1px $item-border-color solid;
    .md-icon {
      font-size: 1.2rem !important;
    }
  }
  .list {
    margin: 0;
    & > li:last-child {
      border: none;
    }
    .item {
      padding: 8px 4px;
      border-bottom: 1px $item-border-color solid;
      display: flex;
      align-items: center;
      .md-icon {
        font-size: 1.2rem !important;
      }
    }
  }
}
