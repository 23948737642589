@import 'styles/variables.scss';
.nap-modal {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .nap-modal-bg {
    opacity: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    animation: nap-modal-bg-animation 0.25s ease-in-out 0.25s alternate;
    animation-fill-mode: forwards;
  }
  .nap-modal-content {
    display: block;
    width: calc(100% - 32px);
    max-width: 480px;
    position: absolute;
    opacity: 0;
    top: -500px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    animation: nap-modal-body-animation 0.75s ease-in-out 0.25s alternate;
    animation-fill-mode: forwards;
    &.free-width {
      max-width: 100%;
    }
    .nap-modal-content-header {
      width: 100%;
      // height: 48px;
      padding: 16px;
      .nap-modal-content-title {
        font-size: 1.2rem;
      }
      .nap-modal-content-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        // height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(0.75);
      }
    }
    .nap-modal-content-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      min-height: 64px;
      padding: 16px;
    }
    .nap-modal-content-footer {
      display: flex;
      width: 100%;
      height: auto;
      padding: 16px;
      /*.nap-modal-content-button {
        display: block;
        background: $col-white;
        color: $col-base;
        border-top: 1px solid $col-gray1;
        border-radius: 0;
        width: 100%;
        height: 48px;
        margin: 0 auto;
        padding: 0;
        font-size: 1.2rem;
        border-right: 1px solid $col-gray1;
      }*/
      .nap-modal-content-button {
        display: block;
        width: auto;
        min-width: 96px;
        height: 32px;
        margin: 0 auto;
        padding: 0;
        font-size: 1.2rem;
      }
    }
  }
}
@keyframes nap-modal-bg-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes nap-modal-body-animation {
  0% {
    opacity: 0;
    top: -500px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 8px;
  }
}
