@import 'styles/variables.scss';


.tmp {
  transition: all 0.05s ease-in-out;
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 10px 0;
  background: #fff;
  z-index: 1;
  box-sizing: border-box;
}

.tmp > section {
  transition: all 0.05s ease-in-out;
  display: block;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.tmp > section > img {
  transition: all 0.05s ease-in-out;
  display: block;
  width: 480px;
  height: 480px;
  border-radius: 50%;
  margin: 0 auto;
  object-fit: cover;
  z-index: 1;
  box-shadow: 8px 8px 0 #01c200;
  -webkit-transform-origin: 50% 50%;
  -webkit-transform: scale(0);
  -webkit-animation-name: mainview;
  -webkit-animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
}

@keyframes mainview {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.tmp > section > h1 {
  display: block;
  position: relative;
  z-index: 2;
  font-family: 'Righteous', cursive;
  font-size: 64px;
  font-weight: normal;
  text-align: center;
  color: #01c200;
  margin: 40px 0 0;
  padding: 0;
}

.tmp > section > p {
  display: block;
  position: relative;
  z-index: 2;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #444;
  margin: 0 0 40px;
  padding: 0;
}

.tmp > section > a {
  display: block;
  box-sizing: border-box;
  text-align: center;
  background: #01c200;
  width: 128px;
  height: 48px;
  padding: 16px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  margin: 0 auto 40px;
  border-radius: 24px;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .tmp {
    width: 100%;
  }

  .tmp > section {
    width: 100%;
  }

  .tmp > section > img {
    width: 240px;
    height: 240px;
  }

  .tmp > section > p {
  }
}

.cf-error-details {
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  color: #999;
  text-align: center;
  padding: 16px;
}

.cf-error-details > h1,
.cf-error-details > p {
  /*display: none;*/
}

.cf-error-details > ul > li {
  display: inline;
}