@import 'styles/variables.scss';
.headerFixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
}

.headerRelative {
  position: relative;
}

.header {
  background: $col-primary;
  display: flex;
  justify-content: space-between;
  padding: 0px 5px 0px 10px;
  &.is-outline {
    background: none;
    z-index: 3;
    .logo .link,
    .menu .link {
      color: #fff;
    }
  }
  .logo {
    color: #fff;
    padding: 3px 0 0 15px;
    font-size: 2.6rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    .link {
      font-size: 2.6rem;
      display: block;
      text-decoration: none;
    }
    .nap-campsite {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      font-size: 2rem;
      color: #fff;
      text-decoration: none;
    }
    .powered-by {
      color: #f2f5f5;
      font-size: 1.2rem;
      font-weight: 400;
      display: block;
    }
  }
  .reserve-confirm {
    font-size: 1.2rem;
    max-width: 80px;
    min-width: 80px;
    padding-top: 6px;
    display: flex;
    align-items: center;
    vertical-align: center;
  }
  .link {
    color: #fff;
    font-weight: bold;
  }
  .menu {
    position: absolute;
    top: 0;
    right: 15px;
    display: flex;
    align-items: center;
    height: 48px;
    .item {
      margin-left: 20px;
    }
    .link {
      color: #fff;
      font-size: 1.3rem;
      font-weight: bold;
      text-decoration: none;
    }
    .search-icon {
      color: #fff;
      svg {
        font-size: 24px;
      }
    }
  }
  .user-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px $col-gray5 solid;
  }
}
.suggest-screen {
  position: fixed;
  z-index: 100;
  top: 0;
  transform: translateZ(0);
  width: 100%;
}
.campaign {
  background: $col-secondary;
  width: 100%;
  .campaign-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 0 0 8px;
    border-top: 1px #fff solid;
    .campaign-title {
      color: $col-white;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 2.4rem;
      .campaign-date {
        color: $col-white;
        font-size: 1rem;
        margin: 0;
      }
    }
    .campaign-icon {
      padding-bottom: 1px;
      .md-icon {
        width: 16px;
        height: 16px;
        font-size: 1.6rem !important;
        font-weight: bold;
        color: $col-white;
      }
    }
  }
}
