@import 'styles/variables.scss';
.header {
  background: $col-primary;
  height: 48px;
  display: flex;
  position: relative;
  // z-index: 10;
  &.is-outline {
    background: none;
    z-index: 3;
    .logo .link,
    .menu .link {
      color: #fff;
    }
  }

  .header-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }

  .logo-container {
    display: flex;
    align-items: center;
    .logo {
      color: #fff;

      .link {
        display: block;
        font-size: 3.5rem;
        font-weight: bold;
        text-decoration: none;
      }
    }
    .caption {
      color: #fff;
      padding: 0 0 0 20px;
      font-size: 1.2rem;
      line-height: 100%;
      font-weight: bold;
    }
    .powered-by {
      color: #f2f5f5;
      margin: 1rem 0 0 10px;
      font-size: 1.2rem;
    }
  }

  .nap-campsite {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }

  .menu {
    display: flex;
    align-items: center;
    .item {
      margin-left: 20px;
      &.is-search {
        width: 270px;
      }
    }
    .link {
      color: #fff;
      font-size: 1.3rem;
      font-weight: bold;
      text-decoration: none;
    }
    &.is-login {
      .is-search {
        width: 180px;
      }
    }
  }
  .user {
    position: relative;
    top: 4px;
    right: 0;
  }
  .user-menu {
    position: relative;
  }
  .user-menu-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
  .user-menu-items {
    margin-top: 39px;
    background: #fff;
    border: 1px $col-gray4 solid;
    padding: 15px;
    border-radius: 3px;
    width: 150px;
    .user-menu-item:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .user-icon {
    color: #fff;
    cursor: pointer;
  }
  .user-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px #fff solid;
  }
  .field-reserve-menu-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    font-weight: normal;
  }
  .field-reserve-menu-items {
    margin-top: 39px;
    background: #fff;
    border: 1px $col-gray4 solid;
    padding: 15px;
    border-radius: 3px;
    width: 308px;
    .field-reserve-menu-item:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .user-menu-link {
    text-decoration: none;
  }
}
.campaign {
  background: $col-secondary;
  width: 100%;
  border-top: 1px #fff solid;
  .campaign-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1020px;
    height: 100%;
    margin: 0 auto;
    .campaign-title {
      color: $col-white;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2.4rem;
      .campaign-date {
        color: $col-white;
        font-size: 1.2rem;
        margin: 0;
      }
    }
    .campaign-icon {
      .md-icon {
        width: 16px;
        height: 16px;
        font-size: 1.6rem !important;
        font-weight: bold;
        color: $col-white;
      }
    }
  }
}
