@import 'styles/variables.scss';

.close-button {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}
.close-button span::before,
.close-button span::after {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  background: $col-base;
}
.close-button span::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.close-button span::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
