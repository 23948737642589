@import 'styles/variables.scss';
.loader-container {
  width: 100vw;
  height: 100vh;
  z-index: 50000;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-inner {
  background: $col-primary !important;
}
