@import "styles/variables.scss";
.ie-alert {
  width: $base-width;
  margin: 10px auto;
  color: $col-error;
  font-weight: bold;
}

.display-environment {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border: 3px #f00 solid;
}
.display-environment-message {
  margin: auto auto;
  font-size: 16px;
  color: #f00;
  font-weight: bold;
}
