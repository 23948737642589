@import 'styles/variables.scss';
.basic-button {
  width: 100%;
  height: 48px;
  padding: 8px;
  margin: 0 auto;
  border-radius: 4px;
  background: $col-primary;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none !important;
  color: #fff;
  z-index: 1;
  border: 0;
  -webkit-transition: opacity 0.25s ease-in-out;
  &:hover {
    opacity: 0.75;
  }
  &.search-button {
    width: 24px;
    height: 24px;
    background: $col-primary;
    padding: 0;
  }
  &.is-lg {
    font-size: 1.6rem;
    height: $button-height-lg;
  }
  &.is-md {
    font-size: 1.4rem;
    height: $button-height-md;
  }
  &.is-sm {
    font-size: 1.2rem;
    padding: 2px 4px;
    height: $button-height-sm;
  }
  &.is-full {
    width: 100%;
  }
  &.is-outline-gray {
    background: #fff;
    border: 1px $col-gray4 solid;
    color: $col-gray6;
  }
  &.is-outline-secondary {
    background: #fff;
    border: 1px $col-secondary solid;
    color: $col-secondary;
  }
  &.is-secondary {
    background: $col-secondary;
  }
  &.is-dark {
    background: $col-gray7;
  }
  &.is-danger {
    background: $col-danger;
  }
  &.is-auto-height {
    height: auto;
  }
  &.fb {
    background: $col-secondary;
  }
  &.negative {
    background: $col-gray3;
    color: $col-base;
  }
  &.is-caution {
    background: $col-error;
  }
  &.is-disabled {
    background: #ccc;
    pointer-events: none;
  }
}
