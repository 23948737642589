@import 'styles/variables.scss';
section.g-container.site-container.link-list .site-title {
  font-size: 1.2rem;
}
section.g-container.site-container.link-list .link-list-item {
  font-size: 1.2rem;
  display: inline-block;
  padding-right: 8px;
  margin: 0 10px 4px 0;
}
.site-container .site-body {
  margin-top: 16px;
}

.container_footer_inner .site-container {
  margin: 16px auto;
}

#fotter_bnr_area {
}
#fotter_bnr_area {
  width: 100%;
  margin: 40px auto 0;
}

#fotter_bnr_area .footer_bnr {
  display: block;
}

#fotter_bnr_area .footer_bnr li {
  width: 50%;
  text-align: center;
  float: left;
}

#fotter_bnr_area .footer_bnr li p {
  font-size: 11px;
  text-align: center;
  padding: 4px 0;
  display: block;
  color: #999;
}

.footer_nav {
  display: block;
  text-align: center;
  width: 100%;
  margin: 0px auto 0;
  padding: 24px 0;
  overflow: hidden;
}

.footer_nav li {
  font-size: 10px;
  font-weight: bold;
  padding: 0;
  text-align: left;
  float: left;
  width: 50%;
  display: block;
  padding: 0 0 0 16px;
}

.footer_nav li a {
  color: #999;
}

.category-location-info {
  padding: 24px 0 0;
}

.container_footer_inner {
  background: $col-gray1;
}

#FooterContainer {
  position: relative;
  z-index: 1;
  margin: 0;
}

.copyright {
  text-align: center;
  padding: 5px;
  font-size: 1rem;
}

.site-footer {
  background: #fff !important;
}

#footer_sns_area {
  margin: 0px auto 24px;
}

.footer_sns_area_ul {
  text-align: center;
}

.footer_sns_area_li {
  margin: 0 10px;
  display: inline-block;
}
