@import 'styles/variables.scss';
.suggest-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
}

.suggest-container {
  position: relative;
  padding: 12px;
  background: $col-primary;
  .close {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: $col-primary;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 68px;
    right: 0;
  }
  .suggest-input-wrap {
    position: relative;
    margin: 0;
    .search-button {
      position: absolute;
      top: 13px;
      right: 20px;
      z-index: 1;
      .md-icon {
        font-size: 2rem !important;
        font-weight: bold;
        padding: 2px;
        width: 16px;
        min-width: 16px;
        height: 16px;
        background: $col-gray1;
        border-radius: 50%;
      }
    }
  }
}
.store-button {
  padding: 12px;
}
