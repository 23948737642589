/*bottom-to-top*/
.bottom-to-top-enter {
  transform: translateY(100%);
  z-index: 10;
}
.bottom-to-top-enter-active {
  transform: translateY(0);
  transition: transform 0.2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.bottom-to-top-exit {
  transform: translateY(0);
  transition: transform 0.2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.bottom-to-top-exit-active {
  transform: translateY(100%);
  z-index: 10;
}

/*right-to-left*/
.right-to-left-enter {
  transform: translateX(100%);
  z-index: 10;
}
.right-to-left-enter-active {
  transform: translateX(0);
  transition: transform 0.2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.right-to-left-exit {
  transform: translateX(0);
  transition: transform 0.2s ease-out;
  z-index: 10;
}
.right-to-left-exit-active {
  transform: translateX(100%);
  z-index: 10;
}

/*left-to-right*/
.left-to-right-enter {
  transform: translateX(0);
  z-index: 10;
}
.left-to-right-enter-active {
  transform: translateX(100%);
  transition: transform 0.2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.left-to-right-exit {
  transform: translateX(100%);
  transition: transform 0.2s ease-out;
  z-index: 10;
}
.left-to-right-exit-active {
  transform: translateX(0);
  z-index: 10;
}

/* Keyvisual */
/* in:true 変更直後 class='fade-enter fade-enter-active' */
.fade-enter {
  transition: opacity 3s ease-in;
  z-index: 1 !important;
}
/* in:true enter適用後 class='fade-enter fade-enter-active' */
.fade-enter-active {
  opacity: 1 !important;
}
/* in:true enterのtimeout経過後 class='fade-enter-done' */
.fade-enter-done {
  opacity: 1 !important;
  z-index: 1 !important;
}
/* in:false 変更直後 class='fade-exit fade-exit-active' */
.fade-exit {
  transition: opacity 3s ease-in;
  z-index: 0 !important;
}
/* in:false exit適用後 class='fade-exit fade-exit-active' */
.fade-exit-active {
  opacity: 0 !important;
}
/* in:false exitのtimeout経過後 class='fade-done' */
.fade-exit-done {
  opacity: 0 !important;
  z-index: 0 !important;
}

/* screen */
/* in:true 変更直後 class='fade-enter fade-enter-active' */
.fade-screen-enter {
  transition: opacity 200ms ease-in;
  z-index: 1 !important;
}
/* in:true enter適用後 class='fade-enter fade-enter-active' */
.fade-screen-enter-active {
  opacity: 1 !important;
}
/* in:true enterのtimeout経過後 class='fade-enter-done' */
.fade-screen-enter-done {
  opacity: 1 !important;
  z-index: 1 !important;
}
/* in:false 変更直後 class='fade-exit fade-exit-active' */
.fade-screen-exit {
  transition: opacity 200ms ease-in;
  z-index: 0 !important;
}
/* in:false exit適用後 class='fade-exit fade-exit-active' */
.fade-screen-exit-active {
  opacity: 0 !important;
}
/* in:false exitのtimeout経過後 class='fade-done' */
.fade-screen-exit-done {
  opacity: 0 !important;
  z-index: 0 !important;
}

/* Accordion */
.accordion-default {
  overflow: hidden;
  transition: height 300ms;
}
/* in:true 変更直後 class='accordion-enter accordion-enter-active' */
.accordion-enter {
}
/* in:true enter適用後 class='accordion-enter accordion-enter-active' */
.accordion-enter-active {
  /* height: 100%; */
}
/* in:true enterのtimeout経過後 class='accordion-enter-done' */
.accordion-enter-done {
  height: auto !important;
}
/* in:false 変更直後 class='accordion-exit accordion-exit-active' */
.accordion-exit {
}
/* in:false exit適用後 class='accordion-exit accordion-exit-active' */
.accordion-exit-active {
}
/* in:false exitのtimeout経過後 class='accordion-done' */
.accordion-exit-done {
}
