// General
$base-width: 1020px;
$font-size: 1.4rem;
$form-font-size: 1.7rem;
$button-height-lg: 48px;
$button-height-md: 36px;
$button-height-sm: 24px;
$form-input-height: 45px;
$border-radius: 4px;

// Color
$col-base: #444;
$col-white: #fff;
$col-primary: #01c200; //00c202
$col-secondary: #06c;
$col-accent: #febb02;
$col-error: #f00;
$col-already-went: #00c900;
$col-favorite: #f33;
$col-danger: #c00;

$col-gray0: #f8f8f8;
$col-gray1: #f2f5f5;
$col-gray2: #e9ecef;
$col-gray3: #dce0e0;
$col-gray4: #ced4da;
$col-gray5: #adb5bd;
$col-gray6: #868e96;
$col-gray7: #495057;
$col-gray8: #343a40;
$col-gray9: #212529;

$col-bg-gradient: linear-gradient(
  225deg,
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 0.75)
);
$col-bg-gradient2: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));

// Grid
// $break-point: 720px;
$break-point1: 640px;

$item-border-color: $col-gray2;
