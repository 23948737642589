@import 'styles/variables.scss';
section.g-container.site-container.link-list h2.site-title {
  font-size: 1.2rem;
}
section.g-container.site-container.link-list a.link-list-item {
  font-size: 1.2rem;
  display: inline-block;
  padding-right: 8px;
  margin: 0 10px 4px 0;
}
.site-container .site-body {
  margin-top: 16px;
}

.container_footer_inner .site-container {
  margin: 16px auto;
}

.fotter_bnr_area {
}
.fotter_bnr_area {
  width: 1020px;
  margin: 24px auto 0;
}

.fotter_bnr_area a {
  text-decoration: none;
}

.fotter_bnr_area ul.footer_bnr {
  display: flex;
  justify-content: space-around;
}

.fotter_bnr_area ul.footer_bnr li p {
  font-size: 11px;
  text-align: center;
  padding: 4px 0;
  display: block;
  color: #999;
}

.footer_nav {
  display: flex;
  width: 1020px;
  margin: 0 auto;
  justify-content: center;
}

.footer_nav li {
  font-size: 10px;
  font-weight: bold;
  padding: 0px 6px;
}

.footer_nav li a {
  color: #111;
  text-decoration: none;
}

.category-location-info {
  padding: 24px 0 40px;
}

.container_footer_inner {
}

.container_footer_2 {
  padding: 24px 0;
  background: $col-gray1;
  border: 1px solid $col-gray4;
}

.copyright {
  text-align: center;
  padding: 16px 0;
  font-size: 1rem;
}

.footer_sns_area {
  margin: 10px auto;
}

.footer_sns_area_ul {
  text-align: center;
}

.footer_sns_area_li {
  margin: 0 10px;
  display: inline-block;
}
