@import './variables.scss';
@import 'node_modules/loaders.css/src/loaders.scss';

html {
  font-size: 62.5%;
  font-feature-settings: 'palt';
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  scroll-behavior: smooth;
}

body {
  font-family: ArialMT, 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3',
    'メイリオ', sans-serif;
  font-size: $font-size;
  color: $col-base;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  margin: 0;
  letter-spacing: 0.75px;
  min-height: 100%;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

:focus {
  outline: 0;
  text-decoration: none;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

small {
  font-size: 1rem;
}

mark {
  background-color: transparent;

  &.highlight-text {
    background-color: $col-accent;
  }
}

a {
  color: $col-secondary;
  text-decoration: none;

  &:hover {
    color: $col-secondary;
  }

  &:active {
    color: $col-secondary;
    opacity: 0.7;
  }
}

p,
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

audio,
canvas,
embed,
iframe,
img,
object,
video {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.g-form-reset {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  margin: 0;
}

.g-link-white {
  color: #fff;

  &:active,
  &:hover,
  &:visited {
    color: #fff;
  }
}
.g-link-no-appearance {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:active,
  &:hover,
  &:visited {
    color: inherit;
  }
}

.inital-loading {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
  }

  .loader-inner {
    border-bottom-color: $col-primary;
  }
}

.u-text-underline {
  text-decoration: underline !important;
}

.g-container {
  width: $base-width;
  margin: 0 auto;
}
.advertisement {
  font-weight: bold;

  .advertisement-content {
    max-width: 1044px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .advertisement-content-banner {
      width: 300px;
      height: 250px;
      margin: 12px;
    }
  }
}

.advertisement iframe {
  height: 250px !important;
}

.nap.is-sp {
  .g-container {
    width: 100%;
    margin: 0 auto 24px;
  }
  .advertisement {
    font-weight: bold;

    .advertisement-content {
      .advertisement-content-banner {
        margin: 25px auto 0;
        width: 300px;
        height: 250px;
      }
    }
  }
}

.nap.is-sp table {
  max-width: 100% !important;
}

#footer_ad .overlay.y1_top {
  top: auto !important;
  bottom: 0px !important;
}

.nap-lazy-load-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: none;
}

.g-override-carousel {
  .carousel.carousel-slider {
    overflow: visible;
  }
  .carousel .control-next.control-arrow {
    right: -30px;
    width: 30px;
  }
  .carousel .control-prev.control-arrow {
    left: -30px;
    width: 30px;
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid $col-gray8;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid $col-gray8;
  }
}

.leaflet-bottom {
  position: fixed !important;
}
