@import "styles/variables.scss";
.alert-message {
  .alert-message-content {
    width: 90%;
    margin: 10px auto;
    text-align: center;
    border: 1px solid #f00;
  }
  .alert-message-ttl {
    background-color: #f00;
    color: #fff;
    padding: 5px 0px;
  }
  .alert-message-body {
    padding: 10px;
    position: relative;
    height: auto;
    overflow: hidden;
    margin: 0;
    transition: height 0.3s ease-out;

    &.is-see-more {
      height: 100px;
      &.is-open {
        height: auto;
        padding-bottom: 40px;
        &::before {
          background: none;
        }
      }
      &::before {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        content: "";
        height: 50px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #fff 100%
        );
      }
    }
  }
  .see-more {
    width: 100px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    background: #f00;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    z-index: 1;
    margin-left: -50px;
    cursor: pointer;
  }
}
