@import 'styles/variables.scss';

.anchor-link-top {
  background: #fff;
  padding: 10px 0;
  text-align: center;
  color: $col-gray6;
  .to-top {
    color: $col-gray6;
  }
}
